<template>
  <div class="flex flex-col w-full m-0 p-0 z-30 sticky top-0 bg-transparent shadow">
    <!--    Top Menu -->
    <div class="flex flex-none w-full h-20 mx-auto pt-2 z-50 bg-white">
      <div class="flex w-full items-center max-w-screen-2xl mx-auto">
        <Icon @click="drawerStore.openDrawer()" name="streamline:interface-setting-menu-2-button-parallel-horizontal-lines-menu-navigation-staggered-three-hamburger" class="w-2/12 text-2xl mx-2"/>
        <div class="flex flex-col pl-2 items-start justify-center w-6/12">
          <NuxtImg
              src="/images/main/logo.png"
              class="h-10 w-24"
              alt=""
          />
          <p class="text-xs mx-auto font-semibold tracking-widest">
            Islamic Centers
          </p>
        </div>
        <BaseButton
            margin-classes="mx-1"
            text-size-class="text-xs"
            is-outlined
            primary-color-class="a-primary"
            hovered-text-color-class="a-neutral-darkest"
            width-class="w-4/12"
            @click.native="
              gotoCreateNewIslamicCenter
            "
        >
          <Icon
              name="mdi:plus"
          />
          List Islamic Center
        </BaseButton>
      </div>
    </div>
    <!--    Top Menu  Ends-->
    <!--    Search Bar -->
    <div class="max-w-screen w-full px-2">
      <IslamicCentersInstantSearch/>
    </div>
    <!--    Search Bar Ends -->
    <BaseAlert alignText="center" class="z-40" />
  </div>
</template>
<script setup lang="ts">
import { useDrawer } from '~/stores/Drawer';
const drawerStore = useDrawer();
function gotoCreateNewIslamicCenter() {
  navigateTo({
    name: "dashboard-islamic-centers-create-new",
  });
}
</script>
